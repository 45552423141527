import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { ISearchService } from '../../shared/material-grid/search-service.interface';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { SearchResultsDataSource } from '../../shared/models/search-results-data-source.model';
import { TemplateMappingModel } from '../../shared/models/template-mapping.model';

@Injectable()
export class TemplateMappingService implements ISearchService {
  apiUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.apiUrl = this.config.get().apiUrl;
  }

  getTemplateMappings(active: boolean): Observable<TemplateMappingModel[]> {
    const uri = this.apiUrl + '/template-mapping/get-all?active=' + active;
    return this.http.get<TemplateMappingModel[]>(uri);
  }

  search(searchFilter: SearchFilter): Observable<any> {
    const uri = this.apiUrl + `/template-mapping/get-mapping-templates`;
    return this.http.post<SearchResultsDataSource<TemplateMappingModel>>(
      uri,
      searchFilter
    );
  }

  createTemplate(template: TemplateMappingModel): Observable<any> {
    const uri = this.apiUrl + `/template-mapping/create-template`;
    return this.http.post<any>(uri, template);
  }

  editTemplate(template: TemplateMappingModel): Observable<any> {
    const uri = this.apiUrl + `/template-mapping/update-template`;
    return this.http.post<any>(uri, template);
  }
}
