import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { LovType } from '../../shared/models/lov-type.model';

@Injectable()
export class LovTypeService {
  apiUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.apiUrl = this.config.get().apiUrl;
  }

  getLovTypes() {
    const uri = this.apiUrl + '/lov-type/get-all';
    return this.http.get<LovType[]>(uri);
  }
}
