import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { storeFreeze } from 'ngrx-store-freeze';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '@env/environment';

import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { authReducer } from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';
import { ClaimGuard } from './auth/claim.guard';
import { RoleGuard } from './auth/role.guard';
import { ConfigService } from './config/config.service';
import { WindowService } from './services/window.service';
import { UserService } from './services/user.service';
import { CacheService } from './services/cache.service';
import { MonitoringService } from './monitoring/monitoring.service';
import { ContractService } from './services/contract.service';
import { TemplateMappingService } from './services/template-mapping.service';
import { DocuSignService } from './services/docusign.service';
import { LovTypeService } from './services/lov-type.service';
import { LovService } from './services/lov.service';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalCookieStrategy } from './enums/msal-cookie-strategy.enum';
import {
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { IMsalProtectedResource } from './config/msal-protected-resource.interface';
import { AdminService } from './services/admin.service';
export const metaReducers: MetaReducer<any>[] = [initStateFromLocalStorage];

const initConfiguration = (configService: ConfigService) => () => {
  return configService.init();
};

const initMSALConfig = (configService: ConfigService): IPublicClientApplication => {
  const msalConfig = configService.get().msalConfig;
  const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1
    || window.navigator.userAgent.indexOf('Trident/') > -1;
  const storeCookie = msalConfig.cookieStrategy === MsalCookieStrategy.Full
    || (isIE && msalConfig.cookieStrategy === MsalCookieStrategy.OnlyIE);


  return new PublicClientApplication({
    auth: {
      clientId: msalConfig.clientId,
      redirectUri: msalConfig.redirectUri,
      authority: msalConfig.authority,
    },
    cache: {
      cacheLocation: msalConfig.cacheStrategy,
      storeAuthStateInCookie: storeCookie,
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => {
          if (msalConfig.logLevel === logLevel) {
            console.log(message);
          }
        },
        logLevel: msalConfig.logLevel,
        piiLoggingEnabled: false
      }
    }
  });
};

const initMSALGuardConfig = (configService: ConfigService): MsalGuardConfiguration => {
  const msalConfig = configService.get().msalConfig;

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: msalConfig.authenticationScopes,
    },
  };
};

const initMSALInterceptorConfig = (configService: ConfigService): MsalInterceptorConfiguration => {
  const msalConfig = configService.get().msalConfig;
  const protectedResourceMap = new Map<string, string[]>();

  msalConfig.protectedResources.forEach((protectedResource: IMsalProtectedResource) =>
    Object.keys(protectedResource)
      .forEach((key: string) => protectedResourceMap.set(key, protectedResource[key]))
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

if (!environment.production) {
  metaReducers.unshift(debug, storeFreeze);
}

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    // Msal
    MsalModule,

    // ngrx
    StoreModule.forRoot(
      {
        auth: authReducer
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([AuthEffects]),

    // ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [],
  providers: [
    LocalStorageService,
    WindowService,
    MonitoringService,
    CacheService,

    MsalService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: initMSALGuardConfig,
      deps: [ConfigService],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: initMSALConfig,
      deps: [ConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: initMSALInterceptorConfig,
      deps: [ConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    ClaimGuard,
    RoleGuard,
    UserService,
    ContractService,
    TemplateMappingService,
    AdminService,
    DocuSignService,
    LovTypeService,
    LovService,
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import only in AppModule'
      );
    }
  }
}
