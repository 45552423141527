import { Injectable, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';

import { ISearchService } from '@app/shared/material-grid/search-service.interface';
import { SearchFilter } from '@app/shared/models/search-filter.model';
import { SearchResultsDataSource } from '@app/shared/models/search-results-data-source.model';
import { ContractSearch } from '@app/shared/models/contract-search.model';
import { Lov } from '@app/shared/models/lov.model';
import { ContractCoversheet } from '@app/shared/models/contract-coversheet.model';

@Injectable()
export class ContractService implements ISearchService {
  apiUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.apiUrl = this.config.get().apiUrl;
  }

  getLovs(lovTypeName: string): Observable<Lov[]> {
    const uri =
      this.apiUrl + '/lov/get-lovs-by-type-name?lovTypeName=' + lovTypeName;
    return this.http.get<Lov[]>(uri);
  }

  search(searchFilter: SearchFilter): Observable<any> {
    const uri = this.apiUrl + `/contract/search`;
    return this.http.post<SearchResultsDataSource<ContractSearch>>(uri, searchFilter);
  }

  createCoversheet(contract: any): Observable<any> {
    const uri = this.apiUrl + `/contract/create-contract`;
    return this.http.post<Guid>(uri, contract);
  }

  createDigitalContract(digitalContract: any): Observable<Guid> {
    const uri = this.apiUrl + '/create-digital-contract';
    return this.http.post<Guid>(uri, digitalContract);
  }

  getCoversheet(id: any): Observable<ContractCoversheet> {
    const uri = this.apiUrl + `/contract/getcoversheet/` + id;
    return this.http.get<ContractCoversheet>(uri);
  }

  getCoversheetPdf(id: any) {
    const uri = this.apiUrl + `/contract/getcoversheetpdf/` + id;
    return this.http.get(uri, { responseType: 'blob' });
  }

  export(filters: SearchFilter) {
    const uri = this.apiUrl + `/contract/export`;
    return this.http.post(uri, filters, { responseType: 'blob' });
  }

  invalidateContract(id: Guid) {
    const uri = this.apiUrl + `/contract/invalidate/` + id;
    return this.http.delete<boolean>(uri);
  }
}
