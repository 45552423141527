import { Component, OnInit } from '@angular/core';
import { DsEvent } from '../models/ds-event.model';
import { ActivatedRoute } from '@angular/router';
import { DocuSignService } from '@app/core/services/docusign.service';

@Component({
  selector: 'anms-ds-event-handler',
  templateUrl: './ds-event-handler.component.html',
  styleUrls: ['./ds-event-handler.component.scss']
})
export class DsEventHandlerComponent implements OnInit {
  message: string;
  loading: boolean;

  constructor(private route: ActivatedRoute,
    private docuSignService: DocuSignService) { }

  ngOnInit() {

    const envelopeId = this.route.snapshot.queryParams['envelopeId'];
    const eventType = this.route.snapshot.queryParams['event'];
    const source = this.route.snapshot.queryParams['source'];

    if (envelopeId && eventType) {
      if (source == 'preview') {
        this.message = 'The status of envelope with ID "' + envelopeId + '" was successfully updated.';
      }
      else {
        this.loading = true;
        const param = {
          envelopeId: envelopeId,
          event: eventType
        };

        this.docuSignService
          .handleDocuSignEvent(param)
          .subscribe(result => {
            this.loading = false;
            this.message = 'The status of envelope with ID "' + envelopeId + '" was successfully updated.';
          },
            error => {
              this.message = 'An error occured while updating the status of envelope with ID ' + envelopeId + '.';
              this.loading = false;
            });
      }
    }
    else {
      this.message = 'An envelope ID and a status must be provided for this request.';
    }
  }
}
