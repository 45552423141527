import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Lov } from '@app/shared/models/lov.model';
import { Observable } from 'rxjs';
import { FormControl, ValidationErrors } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'dropdown-autocomplete',
  templateUrl: './dropdown-autocomplete.component.html',
  styleUrls: ['./dropdown-autocomplete.component.scss']
})
export class DropdownAutocompleteComponent implements OnInit {
  @Input() inputClass: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() options: Lov[];
  @Input() value: Lov;

  @Output() valueSelected = new EventEmitter<any>();

  selectedOption: any;
  filteredOptions: Observable<Lov[]>;
  dropdownInput = new FormControl('', this.requireMatch);
  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.dropdownInput.valueChanges.pipe(
      startWith(''),
      map(name => (name ? this.filter(name) : this.options))
    );
    this.dropdownInput.setValue(this.value);
  }

  private filter(value: any): Lov[] {
    if (typeof value === 'string') {
      return this.options.filter(
        option => option.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      return this.options.filter(
        option =>
          option.name.toLowerCase().indexOf(value.name.toLowerCase()) !== -1
      );
    }
  }

  private requireMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (typeof selection === 'string') {
      return { requireMatch: true };
    }
    return null;
  }

  onOptionSelected($event) {
    this.selectedOption = $event.option.value;
    this.valueSelected.emit(this.selectedOption);
  }

  displayFn(option?: Lov): string | undefined {
    return option ? option.name : undefined;
  }

  getErrorMessage() {
    return this.dropdownInput.hasError('required')
      ? 'This field is required'
      : this.dropdownInput.hasError('requireMatch')
      ? 'Please select a valid option'
      : '';
  }

  checkIfSelectionIsValid() {
    setTimeout(() => {
      if (
        !this.selectedOption ||
        this.selectedOption !== this.dropdownInput.value
      ) {
        this.dropdownInput.setValue(null);
        this.selectedOption = null;
        this.valueSelected.emit(null);
      }
    }, 100);
  }
}
