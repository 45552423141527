import { Component, OnInit, ViewChild } from '@angular/core';
import { SignerModel } from '@app/shared/models/signer.model';
import { ContractService } from '@app/core/services/contract.service';
import { MsalService } from '@azure/msal-angular';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DocuSignService, DocuSignToken } from '../../core/services/docusign.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { switchMap, takeUntil, timeout } from 'rxjs/operators';
import { AuthState, selectorAuth, UserModel, UserService } from '@app/core';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'anms-sign-digitally',
  templateUrl: './sign-digitally.component.html',
  styleUrls: ['./sign-digitally.component.scss']
})
export class SignDigitallyComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  basicInfoValid: boolean;
  ucbSigners: SignerModel[];
  thirdPartySigners: SignerModel[];
  ucbCCRecipients: SignerModel[];
  thirdPartyCCRecipients: SignerModel[];
  contractMetadata: any;
  uploadFormData: FormData;
  isRequestSent: boolean;
  userName: string;
  public user$: Observable<UserModel>;

  @ViewChild('stepper', { static: true }) private matStepper: MatStepper;
  isAuthenticated: any;

  constructor(public contractService: ContractService,
    private adalService: MsalService,
    private store: Store<any>,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private docuSignService: DocuSignService,
    private snackBar: MatSnackBar) {
    this.ucbSigners = new Array();
    this.thirdPartySigners = new Array();
    this.ucbCCRecipients = new Array();
    this.thirdPartyCCRecipients = new Array();
  }

  ngOnInit() {
    this.checkDocuSignTokenAndAuthenticateIfNeeded();
  }

  checkDocuSignTokenAndAuthenticateIfNeeded() {
    if (this.docuSignService.cachedTokenIsValid())
      return;
    else {
      if (this.docuSignService.getRefreshToken()) {
        this.docuSignService.refreshToken().subscribe({
          next: (response: DocuSignToken) => {
            this.docuSignService.cacheToken(response);
            return;
          },
          error: (error => {
            console.log(error);
            this.docuSignService.removeToken();
           }),
        });
      }
      else {

        // this should be a redirect form DocuSign login; get the token, validate it and cache it
        if (this.route.snapshot.queryParams.code) {

          const token = this.docuSignService.getTokenDetails(this.route.snapshot.queryParams.code)
            .subscribe({
              next: (response: DocuSignToken) => {
              response.state = this.route.snapshot.queryParams.state;
              this.docuSignService.cacheToken(response);
              if (response.access_token)
                return;
              else {
                this.snackBar.open('Something went wrong during your authentication to DocuSign. Please try again later.',
                  null,
                  {
                    panelClass: 'snack-error',
                    duration: 4000
                  });
                setTimeout(() => {
                  this.router.navigate(['']);
                }, 4000);
              }
            },
            error: (error => {
              console.log(error);
              this.docuSignService.removeToken();
             }),
          });
        }
        // there is no valid DocuSign token cached & this call is not a redirect from DocuSign Login,
        // so we will redirect the user to DocuSign for login
         else this.docuSignService.redirectToDocuSignLogin(window.location.href);
      }
    }
  }

  onBasicInfoChanged(event) {
    this.basicInfoValid = event;
  }

  onUcbSignersUpdated(signers) {
    this.ucbSigners = signers;
  }

  onThirdPartySignersUpdated(signers) {
    this.thirdPartySigners = signers;
  }

  onUcbCCRecipientsUpdated(recipients) {
    this.ucbCCRecipients = recipients;
  }

  onThirdPartyCCRecipientsUpdated(recipients) {
    this.thirdPartyCCRecipients = recipients;
  }

  signersAdded() {
    return this.ucbSigners.length > 0 && this.thirdPartySigners.length > 0;
  }

  createOrUpdateContract() {

    const docuSignToken = this.docuSignService.getCachedToken();
    if (!docuSignToken)
      this.checkDocuSignTokenAndAuthenticateIfNeeded();

    // const userInfo = this.adalService.userInfo.profile;
    this.user$ = this.store
      .pipe(
        select(selectorAuth),
        takeUntil(this.unsubscribe$),
        switchMap((auth: AuthState) => {
          this.isAuthenticated = auth.isAuthenticated;
          if (this.isAuthenticated) {
            return this.userService.get();
          }

          return of(new UserModel());
        })
      );

    const contractInfo = {
      title: this.contractMetadata.title,
      isDigital: true,
      loggedBy: this.userName,
      amendment: this.contractMetadata.amendment,
      ucbEntityId: this.contractMetadata.ucbEntity.id,
      thirdParty: this.contractMetadata.thirdParty,
      languageGroupId: this.contractMetadata.languageGroup.id,
      originatorId: this.contractMetadata.originator.id,
      contractTypeId: this.contractMetadata.contractType.id,
      keywords: this.contractMetadata.keywords,
      signatureDate: this.contractMetadata.signatureDate,
      effectiveDate: this.contractMetadata.effectiveDate,
      expiryDate: this.contractMetadata.expiryDate
    };
    this.user$.subscribe(res => {
      this.userName = res.givenName + ' ' + res.surname;
      contractInfo.loggedBy = res.givenName + ' ' + res.surname;
    });

    this.uploadFormData = new FormData();
    this.uploadFormData.append('ContractMetadata', JSON.stringify(contractInfo));
    this.uploadFormData.append('UcbSigners', JSON.stringify(this.ucbSigners));
    this.uploadFormData.append('ThirdPartySigners', JSON.stringify(this.thirdPartySigners));
    this.uploadFormData.append('UcbCCRecipients', JSON.stringify(this.ucbCCRecipients));
    this.uploadFormData.append('ThirdPartyCCRecipients', JSON.stringify(this.thirdPartyCCRecipients));
    this.uploadFormData.append('DocuSignAuthToken', docuSignToken);
  }

  uploadComplete(uploadResult) {
    if (uploadResult !== null) {
      this.router.navigate(['']);
    }
  }

  getContractMetadata(data) {
    this.contractMetadata = data;
  }

  response(sent) {
    this.isRequestSent = sent;
  }

  goBack(event) {
    if (event) this.matStepper.previous();
  }
}
