import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeEmitterComponent } from './theme-emitter/theme-emitter.component';
import { AsyncImageComponent } from './async-image/async-image.component';
import { MaterialGridComponent } from './material-grid/material-grid.component';
import { DropdownAutocompleteComponent } from './dropdown-autocomplete/dropdown-autocomplete.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { HttpClientModule } from '@angular/common/http';
import { ContractMetadataComponent } from './contract-metadata/contract-metadata.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AadUserAutocompleteComponent } from './aad-user-autocomplete/aad-user-autocomplete.component';
import { SignerComponent } from './signer/signer.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ColumnFilterComponent } from './material-grid/column-filter/column-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AppRoutingModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSelectModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    ThemeEmitterComponent,
    DropdownAutocompleteComponent,
    AsyncImageComponent,
    AadUserAutocompleteComponent,
    SignerComponent,
    UploadFileComponent,
    ContractMetadataComponent,
    MaterialGridComponent,
    ConfirmationDialogComponent,
    ColumnFilterComponent
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AppRoutingModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,

    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatAutocompleteModule,
    HttpClientModule,
    MatProgressBarModule,

    ThemeEmitterComponent,
    AsyncImageComponent,
    DropdownAutocompleteComponent,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,

    AadUserAutocompleteComponent,
    SignerComponent,
    UploadFileComponent,
    ContractMetadataComponent,
    MaterialGridComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'right', duration: 1500 } }
  ]
})
export class SharedModule { }
