import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Lov } from '../../../../shared/models/lov.model';
import { LovType } from '../../../../shared/models/lov-type.model';
import { Guid } from 'guid-typescript';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './lov-dialog.component.html',
  styleUrls: ['./lov-dialog.component.scss']
})
export class LovDialogComponent implements OnInit {

  lov: Lov;
  lovType: LovType;
  dialogTitle: string;
  isContractRepositoryVisible = false;
  isTemplateIdVisible = false;
  contracrRepository = [
    {
      name: 'ContractShare'
    },
    {
      name: 'ContractRoom'
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<LovDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { lov: Lov, lovType: LovType, dialogTitle: string }) {
    this.lov = data.lov;
    this.lovType = data.lovType;
    this.dialogTitle = data.dialogTitle;
  }

  ngOnInit() {
    if ((this.lovType.name.toUpperCase()) === 'ORIGINATOR') {
      this.isContractRepositoryVisible = true;
      if (this.dialogTitle === 'Edit Lov') {
        this.lov.contractRepository === 'ContractShare' ? this.isTemplateIdVisible = false : this.isTemplateIdVisible = true;
      } else {
        this.isTemplateIdVisible = false;
      }
    }
    else {
      this.isContractRepositoryVisible = false;
    }
    if (!this.lov) {
      this.lov = {
        id: undefined,
        lovTypeId: this.lovType.id,
        name: '',
        contractRepository: '',
        acronym: '',
        templateID: '',
        isVisible: true
      };
    }
  }

  onChange(value: string, lovform: NgForm, controlName: string) {
    const isWhiteSpace = (value || '').trim().length === 0;
    const isValid = !isWhiteSpace;
    if (!isValid) {
      lovform.form.get('name').setErrors({
        invalid: true,
        required: true
      });
    }
  }
  isDisabled(): boolean {
    const iswhitespaceddlb = this.isContractRepositoryVisible === true ? (this.lov.contractRepository || '').trim().length === 0 : false;
    const iswhitespacetxt = (this.lov.name || '').trim().length === 0;
    const iswhitespacetxtid = this.isTemplateIdVisible === true ? (this.lov.templateID || '').trim().length === 0 : false;
    const isValid = (!iswhitespaceddlb && !iswhitespacetxt && !iswhitespacetxtid);
    if (!isValid) {
      return true;
    }
    else {
      return false;
    }
  }
  save() {
    if (this.lov.acronym) this.lov.acronym = this.lov.acronym.trim();
    if (this.lov.name) this.lov.name = this.lov.name.trim();
    if (this.lov.contractRepository) {
      if (this.lov.contractRepository === 'ContractRoom') {
        this.lov.templateID = this.lov.templateID.trim();
      } else {
        this.lov.templateID = 'N/A';
      }
    }
    this.dialogRef.close(this.lov);
  }

  OnContractRepoChange(value: string) {
    this.lov.templateID = '';
    if (value == 'ContractRoom') {
      this.isTemplateIdVisible = true;
    } else {
      this.isTemplateIdVisible = false;
    }

  }

  onTempIdChange(value: string, lovform: NgForm) {
    const isWhiteSpace = (value || '').trim().length === 0;
    const isValid = !isWhiteSpace;
    if (!isValid) {
      lovform.form.get('templateID').setErrors({
        invalid: true,
        required: true
      });
    }
  }

}
