import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { IConfiguration } from './configuration.interface';
import { IWindowService, WindowService } from '../services/window.service';
// import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ConfigService {
  private config: IConfiguration;
  private http: HttpClient;

  constructor(
    // private msalservice: MsalService,
    httpHandler: HttpBackend
    // ,@Inject(WindowService) windowService: IWindowService | undefined
  ) {
    this.http = new HttpClient(httpHandler);
    // windowService = windowService || new WindowService();
    // this.config = (<any>windowService.get()).config;
  }

  public init(): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this.http
        .get<IConfiguration>('/assets/config/config.json')
        .toPromise()
        .then((data: IConfiguration) => {
          this.config = data;
          resolve();
        });
    });
  }

  public get(): IConfiguration {
    return this.config;
  }
}
