import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { LovType } from '../../../shared/models/lov-type.model';
import { LovService } from '../../../core/services/lov.service';
import { MaterialGridComponent } from '../../../shared/material-grid/material-grid.component';
import { LovDialogComponent } from './lov-dialog/lov-dialog.component';

@Component({
  selector: 'anms-lov',
  templateUrl: './lov.component.html',
  styleUrls: ['./lov.component.scss']
})
export class LovComponent implements OnInit, AfterViewInit {

  @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate;
  @ViewChild('editTemplate', { static: true }) editTemplate;
  @ViewChild(MaterialGridComponent, { static: true }) grid;

  displayedColumns = ['name', 'isVisible', 'edit'];

  columns = [
    {
      name: 'name',
      displayName: 'Values'
    },
    {
      name: 'acronym',
      displayName: 'Acronym'
    },
    {
      name: 'isVisible',
      displayName: 'Visible in the interface?',
      cellTemplate: null
    },
    {
      name: 'contractRepository',
      displayName: 'Contract Repository',
    },
    {
      name: 'templateID',
      displayName: 'Template ID',
    },
    {
      name: 'edit',
      displayName: 'Edit',
      cellTemplate: null
    }
  ];

  lovTypes: LovType[];
  selectedLovType: LovType;

  constructor(private route: ActivatedRoute,
    public lovService: LovService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.lovTypes = this.route.snapshot.data.result.lovTypes;
  }

  ngAfterViewInit() {
    const isVisibleColumn = this.columns.filter(function (item) {
      return item.name == 'isVisible';
    })[0];
    isVisibleColumn.cellTemplate = this.checkboxTemplate;

    const editColumn = this.columns.filter(function (item) {
      return item.name == 'edit';
    })[0];
    editColumn.cellTemplate = this.editTemplate;
  }

  onLovTypeChange(event) {
    this.selectedLovType = event.value;
    this.grid.searchFilter.lovTypeId = event.value.id;
    this.grid.search();
    if (event.value.hasAcronym)
      this.displayedColumns.splice(1, 0, 'acronym');
    else {
      if (this.displayedColumns.some(x => x == 'acronym'))
        this.displayedColumns.splice(1, 1);
    }
    if ((event.value.name.toUpperCase()) === 'ORIGINATOR') {
      this.displayedColumns.splice(2, 0, 'contractRepository', 'templateID');
    }
    else {
      if (this.displayedColumns.some(x => x == 'contractRepository') && !event.value.hasAcronym)
        this.displayedColumns.splice(2, 2);
      else if (this.displayedColumns.some(x => x == 'contractRepository') && event.value.hasAcronym)
        this.displayedColumns.splice(3, 2);
    }
  }

  onVisibilityChange(event, lov) {
    lov.isVisible = event.checked;
    this.lovService.updateVisibility(event.checked, lov.id).subscribe(result => {
      this.showNotification(result);
    });
  }

  onEditClicked(lov) {
    const dialogRef = this.openAddEditDialog('Edit Lov', Object.assign({}, lov));
    dialogRef.afterClosed().subscribe(lov => {
      if (lov) {
        this.lovService.update(lov).subscribe(result => {
          this.grid.search();
          this.showNotification(result);
        });
      }
    });
  }

  createLov() {
    const dialogRef = this.openAddEditDialog('Create Lov', null);

    dialogRef.afterClosed().subscribe(lov => {
      if (lov) {
        this.lovService.create(lov).subscribe(result => {
          this.grid.search();
          this.showNotification(result);
        });
      }
    });
  }

  private openAddEditDialog(dialogTitle, lov) {
    return this.dialog.open(LovDialogComponent, {
      width: '600px',
      data: {
        lovType: this.selectedLovType,
        lov: lov,
        dialogTitle: dialogTitle
      }
    });
  }

  private showNotification(result) {
    if (result)
      this.snackBar.open('Update successful', null, { panelClass: 'snack-success' });
    else
      this.snackBar.open('Update unsuccessful', null, { panelClass: 'snack-error' });
  }
}
