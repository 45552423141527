import { Guid } from 'guid-typescript';
import { Lov } from './lov.model';

export class TemplateMappingModel {
  id: Guid;
  name: string;
  contractTypeId: Guid;
  languageGroupId: Guid;
  docuSignTemplateId: string;
  numberOfTPSigners: number;
  numberOfUCBSigners: number;
  active: boolean;
  docuSignTemplateName: string;
  contractType: Lov;
  languageGroup: Lov;
  contractTypeText: string;
  languageGroupText: string;
}
