import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ContractService } from '../services/contract.service';
import { TemplateMappingService } from '../services/template-mapping.service';

@Injectable()
export class CreateModuleResolver implements Resolve<any> {
  constructor(
    private contractService: ContractService,
    private templateMappingService: TemplateMappingService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const ucbEntities = this.contractService
      .getLovs('UCB Entity')
      .pipe(catchError(() => of({})));
    const languageGroups = this.contractService
      .getLovs('Language Group')
      .pipe(catchError(() => of({})));
    const originators = this.contractService
      .getLovs('Originator')
      .pipe(catchError(() => of({})));
    const natures = this.contractService
      .getLovs('Nature')
      .pipe(catchError(() => of({})));
    const contractTemplates = this.templateMappingService
      .getTemplateMappings(true)
      .pipe(catchError(() => of({})));

    return forkJoin(
      ucbEntities,
      languageGroups,
      originators,
      natures,
      contractTemplates
    ).pipe(
      map(allResponses => {
        return {
          ucbEntities: allResponses[0],
          languageGroups: allResponses[1],
          originators: allResponses[2],
          contractTypes: allResponses[3],
          contractTemplates: allResponses[4]
        };
      })
    );
  }
}
