import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { Contract } from '@app/shared/models/contract.model';
import { Lov } from '@app/shared/models/lov.model';
import { ActivatedRoute } from '@angular/router';
import { Moment } from 'moment';

@Component({
  selector: 'anms-contract-metadata',
  templateUrl: './contract-metadata.component.html',
  styleUrls: ['./contract-metadata.component.scss']
})
export class ContractMetadataComponent implements OnInit {
  @Input() useContractTemplate: boolean;
  @Input() alreadySignedContract: boolean;

  @Output() data: EventEmitter<any>;
  @Output() formIsValid: EventEmitter<boolean>;

  contract: Contract;
  basicInfoGroup: FormGroup;
  ucbEntityOptions: Lov[];
  languageGroupOptions: Lov[];
  originatorOptions: Lov[];
  contractTypeOptions: Lov[];
  requiredFieldsCompleted: boolean;
  minDate = new Date(1990, 0, 1);
  maxDate = new Date(2030, 11, 31);
  today = new Date();
  contractTitle: string;
  amd = '';

  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder
  ) {
    this.formIsValid = new EventEmitter<boolean>();
    this.data = new EventEmitter<any>();
  }

  ngOnInit() {
    this.mapResolverData();
    if (!this.alreadySignedContract) {
      this.alreadySignedContract = false;
    }
    if (!this.useContractTemplate) {
      this.useContractTemplate = false;
    }
    this.contractTitle = !this.alreadySignedContract
      ? 'Generated once contract fully signed'
      : 'Will be automatically generated upon completion of metadata';

    this.initForm();

    this.basicInfoGroup.valueChanges.subscribe((changedObj: any) => {
      this.requiredFieldsCompleted = this.basicInfoGroup.valid;
      this.formIsValid.emit(this.basicInfoGroup.valid);
      this.data.emit(this.basicInfoGroup.getRawValue());
    });
  }

  private initForm() {
    this.basicInfoGroup = this._formBuilder.group({
      title: [{ value: this.contractTitle, disabled: true }],
      amendment: [false, Validators.required],
      ucbEntity: ['', Validators.required],
      thirdParty: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(256)])
      ],
      originator: ['', Validators.required],
      keywords: ['', Validators.maxLength(256)],
      effectiveDate: [''],
      expiryDate: ['']
    });

    if (this.alreadySignedContract) {
      this.basicInfoGroup.addControl(
        'signatureDate',
        new FormControl('', Validators.required)
      );
    }

    if (!this.useContractTemplate) {
      this.basicInfoGroup.addControl(
        'languageGroup',
        new FormControl('', Validators.required)
      );
      this.basicInfoGroup.addControl(
        'contractType',
        new FormControl('', Validators.required)
      );
    }
  }

  private mapResolverData() {
    this.ucbEntityOptions = this.route.snapshot.data.result.ucbEntities;
    this.languageGroupOptions = this.route.snapshot.data.result.languageGroups;
    this.originatorOptions = this.route.snapshot.data.result.originators;
    this.contractTypeOptions = this.route.snapshot.data.result.contractTypes;
  }

  checkTitle() {
    if (
      !this.useContractTemplate &&
      this.alreadySignedContract &&
      this.basicInfoGroup.controls['ucbEntity'].value &&
      this.basicInfoGroup.controls['thirdParty'].value.trim() &&
      this.basicInfoGroup.controls['signatureDate'].value &&
      this.basicInfoGroup.controls['contractType'].value
    ) {
      const thirdPartyText = this.basicInfoGroup.controls['thirdParty'].value.trim();
      const thridPartyTrimmed =
        thirdPartyText.length > 20
          ? thirdPartyText.substring(0, 20)
          : thirdPartyText;

      const titleValue =
        this.basicInfoGroup.controls['ucbEntity'].value.name +
        '_' +
        thridPartyTrimmed +
        '_' +
        this.basicInfoGroup.controls['contractType'].value.acronym +
        '_' +
        this.amd +
        this.getDateFormatted(
          this.basicInfoGroup.controls['signatureDate'].value
        );
      this.basicInfoGroup.controls['title'].setValue(titleValue);
    } else {
      this.basicInfoGroup.controls['title'].setValue(this.contractTitle);
    }

    if (!this.basicInfoGroup.controls['thirdParty'].value.trim()) {
      this.basicInfoGroup.controls['thirdParty'].setValue('');
    }
  }
  getDateFormatted(date: Moment) {
    return (
      date.date() + '-' + ('0' + (date.month() + 1)).slice(-2) + '-' + date.year()
    );
  }

  onAmendmentChange(event) {
    this.amd = event.checked ? 'AMD_' : '';
    this.checkTitle();
  }

  onEntitySelected($event: any) {
    this.basicInfoGroup.controls['ucbEntity'].setValue($event ? $event : null);
    this.checkTitle();
  }

  onLanguageSelected($event) {
    this.basicInfoGroup.controls['languageGroup'].setValue(
      $event ? $event : null
    );
  }

  onOriginatorSelected($event) {
    this.basicInfoGroup.controls['originator'].setValue($event ? $event : null);
  }

  onContractTypeSelected($event) {
    this.basicInfoGroup.controls['contractType'].setValue(
      $event ? $event : null
    );
    this.checkTitle();
  }

  getErrorMessage(formControl: string) {
    return this.basicInfoGroup.controls[formControl].hasError('required')
      ? 'This field is required'
      : this.basicInfoGroup.controls[formControl].hasError('maxlength')
        ? 'Maximum length is 256 characters'
        : this.basicInfoGroup.controls[formControl].hasError('matDatepickerMax')
          ? 'Date should be inferior'
          : this.basicInfoGroup.controls[formControl].hasError('matDatepickerMin')
            ? 'Date should be superior'
            : '';
  }
}
