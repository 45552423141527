import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../config/config.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';

@Injectable()
export class UserService {
  private tokenHelper;

  constructor(
    private authService: MsalService,
    private configService: ConfigService,
    private http: HttpClient
  ) {
    this.tokenHelper = new JwtHelperService();
  }

  public get(): Observable<UserModel> {
    return this.http
      .get<UserModel>('https://graph.microsoft.com/v1.0/me')
      .pipe(
        map(u => {
          u.displayName =
            u.displayName || (u.givenName + ' ' + u.surname).trim();
          u.mail = u.mail || u.userPrincipalName.toLocaleLowerCase();
          u.preferredLanguage = u.preferredLanguage || 'en';
          return u;
        })
      );
  }

  public hasRole(roles: string | string[]): Observable<boolean> {
    return this.getRoles().pipe(
      map(userRoles => {
        if (!userRoles) {
          return false;
        }
        if (typeof roles === 'string') {
          return userRoles.includes(roles);
        }
        return roles.some(roleName => userRoles.includes(roleName));
      })
    );
  }

  public getRoles(): Observable<string[]> {

    const currentAccount = this.authService.instance.getAllAccounts();
    if (!currentAccount || currentAccount.length === 0) {
      return of([]);
    }
    return of((currentAccount[0].idTokenClaims as any).roles);
  }

  public getEmail(): Observable<string> {
    const endpoint = this.configService.get().apiUrl;
    const currentAccount = this.authService.instance.getAllAccounts();
    console.log(currentAccount);
    if (currentAccount && currentAccount.length > 0) {
      console.log(currentAccount[0].username);
      return of(currentAccount[0].username);
    }
    return null;
  }
}
