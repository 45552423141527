import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Guid } from 'guid-typescript';
import { TemplateMappingService } from '../../../core/services/template-mapping.service';
import { TemplateMappingModel } from '../../../shared/models/template-mapping.model';

@Component({
  selector: 'anms-contract-template',
  templateUrl: './contract-template.component.html',
  styleUrls: ['./contract-template.component.scss']
})
export class ContractTemplateComponent implements OnInit {
  @Output() formIsValid = new EventEmitter<boolean>();
  @Output() selectedContractTemplateMapping = new EventEmitter<TemplateMappingModel>();

  contractTemplateFormGroup: FormGroup;
  contractTemplateMappings: TemplateMappingModel[];

  constructor(
    private formBuilder: FormBuilder,
    public templateMappingService: TemplateMappingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.contractTemplateMappings = this.route.snapshot.data.result.contractTemplates;
    this.initForm();
  }

  initForm() {
    this.contractTemplateFormGroup = this.formBuilder.group({
      contractTemplate: ['', Validators.required],
      numberOfSigners: [{ value: '', disabled: true }, Validators.required]
    });
  }

  templateSelectionChanged(event) {
    const selectedTemplateId = event.value;
    const selectedTemplate = this.contractTemplateMappings.find(
      t => t.id == Guid.parse(selectedTemplateId)
    );

    this.contractTemplateFormGroup.controls['numberOfSigners'].setValue(
      selectedTemplate.numberOfTPSigners
    );

    this.selectedContractTemplateMapping.emit(selectedTemplate);
    this.formIsValid.emit(this.contractTemplateFormGroup.valid);
  }
}
