import { NewsBannerComponent } from './features/admin/news-banner/news-banner.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MonitoringErrorHandler } from './core';
import { HomeComponent } from './features/home/home.component';
import { AdminComponent } from './features/admin/admin.component';
import { MyContractsComponent } from './features/my-contracts/my-contracts.component';
import { SendModuleComponent } from './features/send-module/send-module.component';
import { SignDigitallyComponent } from './features/sign-digitally/sign-digitally.component';
import { CreateContractComponent } from './features/create-contract/create-contract.component';
import { ContractTemplateComponent } from './features/create-contract/contract-template/contract-template.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';
import { CoversheetComponent } from './features/coversheet/coversheet.component';
import { LovComponent } from './features/admin/lov/lov.component';
import { LovDialogComponent } from './features/admin/lov/lov-dialog/lov-dialog.component';
import { MappingTableComponent } from './features/admin/mapping-table/mapping-table.component';
import { MappingTemplateDialogComponent } from './features/admin/mapping-table/mapping-template-dialog/mapping-template-dialog.component';
import { DsEventHandlerComponent } from './shared/ds-event-handler/ds-event-handler.component';
import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    MatStepperModule,
    NgbModule,

    // core & shared
    CoreModule,
    SharedModule,

    // features
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    // app
    AppRoutingModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: MonitoringErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    MyContractsComponent,
    SignDigitallyComponent,
    SendModuleComponent,
    CreateContractComponent,
    ContractTemplateComponent,
    CoversheetComponent,
    NewsBannerComponent,
    LovComponent,
    LovDialogComponent,
    MappingTableComponent,
    MappingTemplateDialogComponent,
    DsEventHandlerComponent
  ],
  entryComponents: [LovDialogComponent, MappingTemplateDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
