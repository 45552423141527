import { NewsBanner } from './../../shared/models/news-banner.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'anms-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }
  newsBanner: NewsBanner;

  ngOnInit() {
    this.newsBanner = this.route.snapshot.data.result.newsBanner;
  }
}
