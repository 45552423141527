import { CoversheetByIdResolver } from '@app/core/resolvers/coversheetById.resolver';
import { CoversheetComponent } from './features/coversheet/coversheet.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { AdminComponent } from './features/admin/admin.component';
import { MyContractsComponent } from './features/my-contracts/my-contracts.component';
import { SendModuleComponent } from '@app/features/send-module/send-module.component';
import { SignDigitallyComponent } from './features/sign-digitally/sign-digitally.component';
import { RoleGuard } from '@app/core/auth/role.guard';
import { CreateContractComponent } from './features/create-contract/create-contract.component';
import { LovResolver } from './core/resolvers/lov.resolver';
import { CreateModuleResolver } from './core/resolvers/create-module.resolver';
import { AdminResolver } from './core/resolvers/admin.resolver';
import { HomeResolver } from './core/resolvers/home.resolver';
import { DsEventHandlerComponent } from './shared/ds-event-handler/ds-event-handler.component';
import { UserResolver } from './core/resolvers/user.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      redirectToLogin: true
    },
    resolve: { result: HomeResolver }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [RoleGuard],
    pathMatch: 'full',
    data: {
      title: 'Admin Portal',
      redirectToLogin: true,
      roles: ['admin']
    },
    resolve: { result: AdminResolver }
  },
  {
    path: 'create',
    component: CreateContractComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      title: 'Create Module',
      redirectToLogin: true
    },
    resolve: { result: CreateModuleResolver }
  },
  {
    path: 'send',
    component: SendModuleComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      title: 'Send Module',
      redirectToLogin: true
    },
    resolve: { result: LovResolver }
  },
  {
    path: 'sign-digitally',
    component: SignDigitallyComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      title: 'Sign Digitally',
      redirectToLogin: true
    },
    resolve: { result: LovResolver }
  },
  {
    path: 'my-contracts',
    component: MyContractsComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      title: 'My Contracts',
      redirectToLogin: true
    },
    resolve: { result: UserResolver }
  },
  {
    path: 'coversheet/:id',
    component: CoversheetComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      title: 'Coversheet',
      redirectToLogin: true
    },
    resolve: { result: CoversheetByIdResolver }
  },
  {
    path: 'ds',
    component: DsEventHandlerComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
    data: {
      title: 'Docusign Event Handler',
      redirectToLogin: true
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule],
  providers: [
    LovResolver,
    CreateModuleResolver,
    CoversheetByIdResolver,
    AdminResolver,
    HomeResolver,
    UserResolver
  ]
})
export class AppRoutingModule { }
