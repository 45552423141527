import { Title, Meta } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewChecked, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {
  ActivationEnd,
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  NavigationStart,
  ResolveEnd
} from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
  UserService,
  UserModel,
  ActionAuthLogin,
  ActionAuthLogout,
  selectorAuth,
  ActionAuthLoginSucceeded,
  AuthState
} from '@app/core';
import { routerTransition } from './shared/animations/router.transition';
import { ConfigService } from './core/config/config.service';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment as env } from '@env/environment';

import { MonitoringService } from './core/monitoring/monitoring.service';

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  private unsubscribe$: Subject<void> = new Subject<void>();

  @HostBinding('class') componentCssClass;

  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = require('../assets/logo.png');
  homePage = true;
  public user$: Observable<UserModel>;
  adminRoleName = this.configService.get().adminRoleName;
  navigation = [
    {
      link: 'admin',
      label: 'Admin Portal',
      requiredRole: this.adminRoleName
    },
    {
      link: 'ContractShare',
      label: 'ContractShare',
      externalLink: true
    },
    {
      link: this.isProd ? 'ContractRoomProd' : 'ContractRoomStaging',
      label: 'ContractRoom',
      externalLink: true
    },
    { link: 'help', label: 'Help', externalLink: true }
  ];
  navigationSideMenu = [
    ...this.navigation,
    { link: 'settings', label: 'Settings' }
  ];
  userName: string;
  isAdmin: boolean;
  public isAuthenticated = false;
  loadingRoute = true;
  private apiUrl: string;
  constructor(
    public overlayContainer: OverlayContainer,
    private router: Router,
    private store: Store<any>,
    private titleService: Title,
    private monitoringService: MonitoringService,
    private configService: ConfigService,
    translate: TranslateService,
    private authService: MsalService,
    private userService: UserService,
    private meta: Meta,
    private cdr: ChangeDetectorRef
  ) {
    translate.setDefaultLang('en');
    this.apiUrl = configService.get().apiUrl;
  }

  ngOnInit(): void {
    this.subscribeToIsAuthenticated();
    this.subscribeToRouterEvents();
    this.monitoringService.start();
    this.setTheme();
    this.router.events.subscribe(event => {
      if (
        event instanceof RouteConfigLoadStart ||
        event instanceof NavigationStart
      ) {
        this.loadingRoute = true;
      } else if (
        event instanceof ResolveEnd ||
        event instanceof NavigationEnd
      ) {
        this.loadingRoute = false;
      }
    });
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  private subscribeToIsAuthenticated() {
    try
    {
      this.authService.handleRedirectObservable().subscribe({
        next: (result: AuthenticationResult) => {
        if (!!result?.account) {
          this.store.dispatch(new ActionAuthLoginSucceeded(result.account));
        }
       },
       error: (error => {
        console.log(error);
        this.authService.logout();
        this.store.dispatch(new ActionAuthLogout());
       }),
      });

    this.store
      .pipe(
        select(selectorAuth),
        takeUntil(this.unsubscribe$)).
      subscribe((auth: AuthState) => {
        this.isAuthenticated = auth.isAuthenticated;
        if (this.isAuthenticated) {
          this.user$ = this.userService.get();
          this.getUserDetails();
        } else {
          this.user$ = of(new UserModel());
        }
      });
    }
    catch (error) {
      console.log(error);
      this.authService.logout();
      this.store.dispatch(new ActionAuthLogout());
    }

  }
  private getUserDetails() {
    this.user$.subscribe(res => this.userName = res.givenName + ' ' + res.surname);
    this.userService
      .hasRole(this.configService.get().adminRoleName)
      .subscribe(result => {
        this.isAdmin = result;
      });

    this.setTheme();
  }

  private setTheme() {
    const effectiveTheme = 'default-theme';
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    classList.add(effectiveTheme);
    window.setTimeout(() => this.setBrowserThemeColor(), 0);
  }

  private setBrowserThemeColor() {
    const color = getComputedStyle(document.getElementById('theme-primary'))
      .color;
    this.meta.updateTag({ name: 'theme-color', content: color });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onLoginClick() {
    this.store.dispatch(new ActionAuthLogin());
  }

  onLogoutClick() {
    this.authService.logout();
    this.store.dispatch(new ActionAuthLogout());
  }

  onChangeLogClick() {
    this.router.navigate(['changelog']);
  }

  // onHealthClick() {
  //   window.open(`${this.apiUrl.replace('/api', '')}/healthchecks-ui`);
  // }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof ActivationEnd || event instanceof NavigationEnd
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(event => {
        if (event instanceof ActivationEnd) {
          this.setPageTitle(event);
        }

        if (event instanceof NavigationEnd) {
          this.homePage = this.router.url === '/';
        }
      });
  }

  private setPageTitle(event: ActivationEnd) {
    let lastChild = event.snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const { title } = lastChild.data;
    this.titleService.setTitle(
      title ? `${title} - ${env.appName}` : env.appName
    );
  }

  /*private trackPageView(event: NavigationEnd) {
    (<any>window).ga('set', 'page', event.urlAfterRedirects);
    (<any>window).ga('send', 'pageview');
  }*/

  openExternalLink(menuItem) {
    const config = this.configService.get();
    const url = config[menuItem.link];
    window.open(url, '_blank');
  }

  hasRole(role: string) {
    if (role === '') {
      return true;
    }
    if (role === this.adminRoleName) {
      return this.isAdmin;
    }
    return false;
  }
}
