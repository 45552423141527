import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'anms-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit, AfterViewInit {

  @Input() filterValue: string;

  @Input() clear: Subject<boolean>;

  @Input() placeholder: string;

  @Output() filtering = new EventEmitter<any>();

  @ViewChild('gridFilter', { static: true }) filter: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return {
          filterId: event.target.id,
          value: event.target.value
        };
      }),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(filter => {
      this.filtering.emit(filter.value);
    });

    if (this.clear)
      this.clear.subscribe(shouldClear => {
        if (shouldClear)
          this.filter.nativeElement.value = '';
      });
  }

}
