import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SearchFilter } from '../models/search-filter.model';
import { ISearchService } from './search-service.interface';
import { catchError, finalize } from 'rxjs/operators';

export class MaterialGridDataSource implements DataSource<any> {

  private subject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public totalRecords = 0;

  constructor(private searchService: ISearchService) { }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
    this.loadingSubject.complete();
  }

  search(searchFilter: SearchFilter) {

    this.loadingSubject.next(true);

    this.searchService.search(searchFilter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe((entities) => {
        this.totalRecords = entities.totalRecords;
        this.subject.next(entities.records);
      });
  }
}
